import React, { useState } from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Button } from "react-bootstrap"
import RegistrationModal from "../components/RegistrationModal"
import useWindowSize from "../utils/useWindowSize"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import { navigate } from "gatsby"
import { AiOutlineCaretRight } from "react-icons/ai"

export default function Retail_Hospitals() {
  const { width } = useWindowSize()
  const [isModalOpen, setModalStatus] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const user = isBrowser ? JSON.parse(window.localStorage.getItem("user")) : {}

 // console.log(user)

  function openModal() {
    setModalStatus(true)
  }

  function closeModal() {
    setModalStatus(false)
  }
  return (
    <Layout>
      <RegistrationModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        type="retail"
      />
      <Container
        fluid
        style={{
          backgroundColor: "white",
          color: "black",
          paddingTop: "4rem",
          minHeight: `${width < 768 ? "50vh" : "90vh"}`,
        }}
      >
        <Seo
          title="Discover, compare, order, medication for pharmacies"
          description="Browse the huge choice of medical products available from pharma suppliers all across Kenya. Find alternatives for products and make data driven decisions"
          keywords="Pharmaceutical companies in kenya,Medical suppliers in kenya,Medical suppliers in Nairobi,Pharmaceutical supply chain,List of pharmaceutical companies in kenya,Pharmaceutical wholesalers in Nairobi,Pharmaceutical distributors in kenya,Pharmaceutical distributors in Nairobi,companies looking for distributors in kenya,pharmaceutical importers in kenya"
          img="https://www.patameds.com/static/329b8772098c9cefc2e39499baac2550/5e32c/22.webp"
          siteUrl="https://www.patameds.com/retail_hospitals"
          canonical="https://www.patameds.com/retail_hospitals"
        ></Seo>
        <Row
          style={{
            paddingTop: `${width < 768 ? "10px" : "100px"}`,
            display: "flex",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xs={12} sm={4} style={{ margin: "auto" }}>
            <h1
              style={{
                color: "#03989e",
                fontWeight: "700",
                fontStyle: "normal",
              }}
            >
              Buying online is Quick, Safe and Easy
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontStyle: "normal",
                textAlign: "justify",
                width: "80%",
              }}
            >
              Browse the huge choice of products available from Sellers all
              across the region. Find alternatives for products and data
              insights.
            </p>

            <Button
              size="lg"
              style={{ backgroundColor: "#00A14B", marginBottom: "1rem" }}
              onClick={() => {
                user?navigate("/"):navigate("/register")
              }}
            >
              Start Buying <AiOutlineCaretRight style={{ display: "inline" }} />
            </Button>
          </Col>
          <Col xs={12} sm={6} style={{ padding: "10px" }}>
            <StaticImage
              src="../images/canva/22.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="Professional Buying medication online"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: "#03989e",
          color: "white",
          minHeight: "100%",
        }}
      >
        <div
          style={{
            paddingTop: `${width < 768 ? "0px" : "20px"}`,
          }}
        >
          <Row style={{ padding: "10px" }}>
            <h2 style={{ margin: "auto" }}>What you get</h2>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                <StaticImage
                  src="../images/canva/13.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="convenience"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>convenience</p>
              </div>
              <div>
                <p>
                  Conveniently ordering pharmaceutical products online from wholesalers

                </p>
              </div>
            </Col>

            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                <StaticImage
                  src="../images/canva/14.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="data driven decisions"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>
                  data driven decisions
                </p>
              </div>
              <div>
                <p>
                  Procurement data analysis (costs, quantities, suppliers) for
                  better decision making in medication purchase
                </p>
              </div>
            </Col>

            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                <StaticImage
                  src="../images/canva/15.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="save cost and time"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>save cost & time</p>
              </div>
              <div>
                <p>
                  Product research and pricing comparisons from multiple wholesalers
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <div style={{ width: "70%", margin: "auto" }}>
        <Button
          size="lg"
          style={{ backgroundColor: "#00A14B", border: "1px solid #fff" }}
          onClick={openModal}
        >
          Verify your retail pharmacy for a seamless service delivery <AiOutlineCaretRight style={{ display: "inline" }} />
        </Button>
      </div>
      <Container
        fluid
        style={{
          minHeight: `${width < 758 ? "50vh" : "100vh"}`,
          backgroundColor: "#03989e",
          color: "black",
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "150px"}`,
          }}
        >
          <Col
            xs={12}
            sm={4}
            style={{ margin: "auto", backgroundColor: "#03989e" }}
          >
            <div style={{ color: "white" }}>
              <h2>Optimize your pharmacies</h2>
            </div>
            <div style={{ color: "white" }}>
              <p>
                Access information on medication availability from a pool of
                130+ major distributors stocking 6300+ medications
              </p>
              <p>
                Leverage data from cost tracking, supplier management, and
                pricing to make better data driven decisions in medication
                purchase
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} style={{ padding: "10px" }}>
            <StaticImage
              src="../images/canva/23.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="Optimize your pharmacies"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: "white",
          color: "black",
          minHeight: "100%",
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "150px"}`,
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Col
            xs={12}
            sm={3}
            style={{
              backgroundColor: "#03989e5c",
              margin: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                margin: "20px 20px",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <div>
                <StaticImage
                  src="../images/canva/17.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="Trusted"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <h4 style={{ margin: "20px" }}>Trusted</h4>
              <p>Buy with confidence from trusted and verified suppliers</p>
            </div>
          </Col>

          <Col
            xs={12}
            sm={3}
            style={{
              backgroundColor: "#03989e5c",
              margin: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                margin: "20px 20px",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <div>
                <StaticImage
                  src="../images/canva/17.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="Convenient"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <h4 style={{ margin: "20px" }}>Convenient</h4>
              <p>Convenient to shop with ease and with great terms</p>
            </div>
          </Col>

          <Col
            xs={12}
            sm={3}
            style={{
              backgroundColor: "#03989e5c",
              margin: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                margin: "20px 20px",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <div>
                <StaticImage
                  src="../images/canva/17.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="Risk Free"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <h4 style={{ margin: "20px" }}>Risk Free</h4>
              <p>
                No fees are needed, start saving today with free and easy sign
                up
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          minHeight: `${width < 758 ? "50vh" : "100vh"}`,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "150px"}`,
          }}
        >
          <Col xs={12} sm={4} style={{ margin: "auto", padding: "10px" }}>
            <div>
              <h4>
                Join other pharmacies and hospitals in embracing technology with
                Patameds
              </h4>
            </div>
            <div style={{ padding: "20px 0" }}>
              <Button
                size="lg"
                style={{ backgroundColor: "#00A14B" }}
                onClick={() => {
                  user?navigate("/"):navigate("/register")
                }}
              >
                Sign Up <AiOutlineCaretRight style={{ display: "inline" }} />
              </Button>
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            style={{
              padding: "10px",
              minHeight: "100%",
            }}
          >
            <StaticImage
              src="../images/canva/11.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="sign up"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>
    </Layout >
  )
}
